import { isDevelopment } from '@lib/env';
import { GenesisError } from '@lib/types/errors';
import { Profile, TokenSet } from 'next-auth';
import { Provider } from 'next-auth/providers';
import { createGenesisAuthClient } from './genesis-auth-client';

type GenesisProviderConfig = {
  clientId: string;
  clientSecret: string;
  baseURL: string;
};

export const GENESIS_PROVIDER_ID = 'unity-id';

export function createGenesisProvider(config: GenesisProviderConfig) {
  const genesisAuthClient = createGenesisAuthClient(config.baseURL);
  // Unfortunately Genesis does not fully follow OAuth2 specs for retrieving the ACCESS TOKEN and USER INFO.
  // Therefore we have to override both requests with our own implementations.
  const genesisProvider: Provider = {
    id: GENESIS_PROVIDER_ID,
    name: 'Unity', // Id & name can be visible to user, therefore not using the term Genesis here
    type: 'oauth',
    clientId: config.clientId,
    clientSecret: config.clientSecret,
    checks: isDevelopment() ? ['none'] : undefined,
    authorization: {
      url: `${config.baseURL}/oauth2/authorize`,
      params: {
        scope: '',
        locale: 'en_US',
      },
    },
    token: {
      // For some reason Genesis requires a x-www-form-urlencoded formatted body including all params to retrieve the access_token.
      // See "Exchange access token" at https://genesis-docs.unity3d.com/oauth-&-identity/AccessToken/
      async request({ provider, params }) {
        if (!params.code) {
          throw new Error(
            'code parameter is missing from Access Token request'
          );
        }
        const data = await genesisAuthClient.getAccessToken(
          provider.callbackUrl,
          params.code
        );

        return { tokens: data as TokenSet };
      },
    },
    userinfo: {
      // Could not find proper Genesis documentation for retrieving user info.
      // Therefore mirroring behavior from legacy Drupal code:
      // https://github.com/Unity-Technologies/web-module-cms-foundation/blob/341d3295f93ae07f85c99af276cb87da6e0f993d/cms_oauth/src/CmsOauthGenesisOauthService.php#L544
      async request(context) {
        const response = await fetch(
          `${config.baseURL}/users/${context.tokens.user}`,
          {
            headers: {
              Authorization: `Bearer ${context.tokens.access_token}`,
            },
          }
        );
        const data = await response.json();
        if (!response.ok) {
          const error = data as GenesisError;
          throw new Error(
            `Fetching user info failed with status ${
              response.status
            }. Reason: ${JSON.stringify(error.details)}`
          );
        }
        return data as Profile;
      },
    },
    profile(profile) {
      return {
        id: profile.id,
        name: profile.name?.fullName,
        email: profile.emails?.[0]?.value,
        image: profile.avatar ?? null, // has to be null instead of undefined, so its serializable as server side props
      };
    },
  };

  return genesisProvider;
}
