import c from 'classnames';

type OverlayProps = {
  className?: string;
};

export const Overlay = ({ className }: OverlayProps) => {
  return (
    <div
      className={c(
        'z-overlay pointer-events-none fixed left-0 h-full w-full bg-overlay',
        className
      )}
    />
  );
};
